import { FNCVariant } from 'shared/types/product/Product';
import { SWRResponse } from 'swr';
import Swatches from './swatches';

interface ColourOptionsProp {
  response: SWRResponse<FNCVariant[]>;
  activeVariant: FNCVariant;
  onColourOptionClick(variant: FNCVariant): void;
}

export default function ColourOptions({
  response,
  activeVariant,
  onColourOptionClick,
}: ColourOptionsProp): JSX.Element {
  return (
    <>
      {!response.data && !response.error && (
        <div className="flex flex-wrap gap-x-1 p-0.5">
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
          <div className="h-[20px] w-[20px] animate-pulse items-center rounded-full bg-skeleton" />
        </div>
      )}

      {response.data && (
        <Swatches variants={response.data} activeVariant={activeVariant} onSelect={onColourOptionClick} />
      )}
    </>
  );
}
