import { RefObject, useContext } from 'react';
import { FNCVariantAvailability } from 'shared/types/furniturechoice/variant';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import { sdk } from '~/sdk';
import useIsInView from '../use-is-in-view';

interface Args {
  sku: string;
  ref?: RefObject<HTMLElement>;
}

export default function useVariantAvailability({ sku, ref }: Args): SWRResponse<FNCVariantAvailability> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { hasBeenViewed } = useIsInView({ ref });

  return useSWRImmutable<FNCVariantAvailability>(
    (ref?.current && hasBeenViewed) || !ref ? `/action/furniturechoice-inventory/getVariantAvailability/${sku}` : null,
    async () => {
      return executeWithProgress<FNCVariantAvailability>(async () => {
        const response = await sdk.callAction<FNCVariantAvailability>({
          actionName: 'furniturechoice-inventory/getVariantAvailability',
          payload: { sku },
          skipQueue: true,
        });

        if (response.isError) {
          throw response.error;
        }

        return response.data;
      });
    },
  );
}
