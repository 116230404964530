'use client';

import { useContext, useMemo } from 'react';
import { useSearchParams, usePathname, useRouter } from 'next/navigation';
import clsx from 'clsx';
import { FNCProduct } from 'shared/types/product/Product';
import { PaginatedResult } from 'shared/types/result';
import ProductCard, { imageSizingType } from '~/components/furniturechoice/product-card';
import ProgressIndicatorContext from '~/helpers/contexts/furniturechoice/progress-indicator/context';
import { useFormat } from '~/helpers/hooks/useFormat';
import { sendGA4Event } from '~/utils/send-ga4-event';
import GridHeader from './grid-header';

interface ProductCardGridProps {
  pagedProducts: PaginatedResult<FNCProduct>;
  activeSortOrder: string;
  filterFacets: JSX.Element[] | undefined;
  isFilterOpen: boolean;
  onSortChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onFilterOpen: () => void;
}

export default function ProductCardGrid({
  pagedProducts,
  activeSortOrder,
  filterFacets,
  isFilterOpen,
  onSortChange,
  onFilterOpen,
}: ProductCardGridProps): JSX.Element {
  const { navigateWithProgress } = useContext(ProgressIndicatorContext);
  const { formatMessage } = useFormat({ name: 'furniturechoice' });
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const pageSizeIncrements = 60;
  const imageSizing: imageSizingType = { colSpanPercent: 0.5, colSpanPercentLg: 0.25 };

  async function handleLoadClick(event: React.MouseEvent<HTMLElement>) {
    event.preventDefault();
    const params = new URLSearchParams(searchParams.toString());
    const pageSizeCount = safePagedProducts.length + pageSizeIncrements;
    params.set('pageSize', pageSizeCount.toString());
    navigateWithProgress(async () => router.push(`${pathname}?${params.toString()}`, { scroll: false }));
    sendGA4Event('multifacet', 'interaction_type', 'View More', 'element', `${pageSizeCount / pageSizeIncrements}`);
  }

  const safePagedProducts = useMemo(() => (pagedProducts ? pagedProducts.items : []), [pagedProducts]);

  return (
    <div>
      <GridHeader
        isFilterDrawerOpen={isFilterOpen}
        activeSortOrder={activeSortOrder}
        onSortChange={onSortChange}
        onFilterLabelClick={onFilterOpen}
        productTotal={pagedProducts?.total || 0}
      />
      <div className="grid grid-cols-4 gap-12">
        <div
          className={clsx(
            'hidden lg:block [&>:first-child]:!border-none [&>div]:border-t [&>div]:border-t-grey-3 [&>div]:pb-2.5 [&>div]:pt-4',
            {
              'lg:hidden': !isFilterOpen,
            },
          )}
        >
          {filterFacets?.map((el) => el)}
        </div>
        <div
          className={clsx('mt-7.5 grid grid-cols-2 gap-3 gap-y-6', {
            'col-span-3 lg:grid-cols-3': isFilterOpen,
            'col-span-4 lg:grid-cols-4': !isFilterOpen,
          })}
        >
          {safePagedProducts.map((p) => (
            <ProductCard key={p.selectedVariant.sku} product={p} imageSizing={imageSizing} />
          ))}
        </div>
      </div>
      <div className="mx-auto my-8 flex max-w-xs flex-col">
        <span className="text-center text-16 font-light leading-5">
          {formatMessage({
            id: 'tastics.category.collection.product-card-grid.showing_count',
            values: { currentCount: safePagedProducts.length, totalCount: pagedProducts?.total ?? 0 },
          })}
        </span>
        <div className="mx-3 mt-[10px] h-[5px] rounded-md bg-grey-2">
          <div
            className="h-[5px] rounded-md bg-salmon-1"
            style={{ width: `${(safePagedProducts.length / (pagedProducts?.total || 0)) * 100}%` }}
          ></div>
        </div>
        {pagedProducts?.total > pagedProducts.count && (
          <button onClick={handleLoadClick} className="surface-salmon-1 mt-8 w-full rounded-md p-4">
            {formatMessage({
              id: 'tastics.category.collection.product-card-grid.load_more',
            })}
          </button>
        )}
      </div>
    </div>
  );
}
