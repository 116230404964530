import clsx from 'clsx';
import { MdCheck, MdErrorOutline } from 'react-icons/md';
import { FNCVariantAvailability } from 'shared/types/furniturechoice/variant';
import { useFormat } from '~/helpers/hooks/useFormat';
import buildStockFormattedDate from '~/helpers/utils/furniturechoice/build-stock-formatted-date';

interface VariantCardStockStatusProp {
  availability: FNCVariantAvailability;
}

export default function VariantCardStockStatus({ availability }: VariantCardStockStatusProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  const { isOnStock, isOnLowStock, expectedDelivery, isRds, isDiscontinued, stockSummary } = availability;

  let stockStatus: 'in-stock' | 'low-stock' | 'discontinued' | 'no-stock-with-incoming' | 'no-stock' | null = null;

  if (isOnStock) {
    stockStatus = isOnLowStock ? 'low-stock' : 'in-stock';
  } else {
    stockStatus = expectedDelivery ? 'no-stock-with-incoming' : 'no-stock';
  }

  if (isDiscontinued || (isRds && !isOnStock)) {
    stockStatus = 'discontinued';
  }

  return (
    <p
      data-testid="variant-card-stock-status"
      className={clsx('flex items-center text-12 font-medium leading-3.5 text-green-2', {
        'text-red-2': stockStatus === 'low-stock' || stockStatus === 'discontinued',
      })}
    >
      {stockStatus === 'in-stock' && (
        <>
          <MdCheck size={12} className="mr-1 inline" />
          <span data-testid="in-stock">{stockSummary?.short}</span>
        </>
      )}

      {stockStatus === 'low-stock' && (
        <>
          <MdErrorOutline size={12} className="mr-1 inline" />
          <span data-testid="low-stock">{stockSummary?.short}</span>
        </>
      )}

      {stockStatus === 'no-stock-with-incoming' && (
        <span data-testid="no-stock-with-incoming">
          {formatMessage({
            id: 'components.variant-card-stock-status.out_of_stock_with_expected_delivery',
            values: {
              date: buildStockFormattedDate(new Date(expectedDelivery as string)),
            },
          })}
        </span>
      )}

      {stockStatus === 'no-stock' && (
        <span data-testid="no-stock">{formatMessage({ id: 'components.variant-card-stock-status.out_of_stock' })}</span>
      )}

      {stockStatus === 'discontinued' && (
        <span data-testid="discontinued">
          {formatMessage({ id: 'components.variant-card-stock-status.unavailable' })}
        </span>
      )}
    </p>
  );
}
