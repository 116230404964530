function getDaySuffix(day: number): string {
  if (day > 3 && day < 21) return 'th';
  switch (day % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
}

export default function buildStockFormattedDate(date: Date): string {
  const day = date.getDate();
  const daySuffix = getDaySuffix(day);

  const formattedDate = date.toLocaleDateString('en-US', {
    day: 'numeric',
    month: 'long',
  });

  return `${day}${daySuffix} ${formattedDate.split(' ')[0]}`;
}
