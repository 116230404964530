import { RefObject, useContext } from 'react';
import { FNCVariant } from 'shared/types/product/Product';
import { SWRResponse } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { sdk } from 'sdk';
import { ProgressIndicatorContext } from '~/helpers/contexts/furniturechoice/progress-indicator';
import useIsInView from '../use-is-in-view';

interface Args {
  sku: string;
  ref?: RefObject<HTMLElement>;
}

export default function useColourOptionVariants({ sku, ref }: Args): SWRResponse<FNCVariant[]> {
  const { executeWithProgress } = useContext(ProgressIndicatorContext);
  const { hasBeenViewed } = useIsInView({ ref });

  return useSWRImmutable<FNCVariant[]>(
    (ref?.current && hasBeenViewed) || !ref ? `/action/furniturechoice-product/getColourOptionVariants/${sku}` : null,
    async () => {
      return executeWithProgress<FNCVariant[]>(async () => {
        const response = await sdk.callAction<FNCVariant[]>({
          actionName: 'furniturechoice-product/getColourOptionVariants',
          payload: { sku },
          skipQueue: true,
        });

        if (response.isError) {
          throw response.error;
        }

        return response.data;
      });
    },
  );
}
