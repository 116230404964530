import clsx from 'clsx';
import { MdCheckCircle } from 'react-icons/md';
import { FNCVariant } from 'shared/types/product/Product';
import ContentfulImage from '~/components/furniturechoice/contentful-image';

interface SwatchProps {
  variant: FNCVariant;
  activeVariantSku: string;
  onClick(variant: FNCVariant): void;
}

export default function Swatch({ variant, activeVariantSku, onClick }: SwatchProps): JSX.Element {
  return (
    <button
      className={clsx(
        'relative box-content block aspect-square rounded-full border border-transparent p-0.5 hover:cursor-pointer',
        {
          '!border-green-1': activeVariantSku === variant.sku,
        },
      )}
      onClick={() => onClick(variant)}
    >
      {activeVariantSku === variant.sku && (
        <MdCheckCircle className="absolute -left-0.5 -top-0.5 z-10 h-[12px] w-[12px] rounded-full bg-white fill-green-1" />
      )}
      <ContentfulImage
        className="h-5 w-[20px] rounded-full"
        sizes="20px"
        colSpanPercent={1}
        alt={variant.metafields.productName}
        title={variant.metafields.productName}
        src={variant.metafields.swatch?.uri}
      />
    </button>
  );
}
