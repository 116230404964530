import { MdErrorOutline } from 'react-icons/md';
import { FNCVariantAvailability } from 'shared/types/furniturechoice/variant';
import { SWRResponse } from 'swr';
import { useFormat } from '~/helpers/hooks/useFormat';
import VariantCardStockStatus from '../../variant-card-stock-status';

interface StockSummaryProp {
  response: SWRResponse<FNCVariantAvailability>;
}

export default function StockSummary({ response }: StockSummaryProp): JSX.Element {
  const { formatMessage } = useFormat({ name: 'furniturechoice' });

  return (
    <>
      {!response.data && !response.error && <div className="h-4 w-full animate-pulse rounded-md bg-skeleton" />}

      {response.error && (
        <p className="flex items-center text-12 font-medium leading-3.5 text-red-2">
          <MdErrorOutline size={12} className="mr-1 inline" />
          {formatMessage({ id: 'components.product-card.unavailable' })}
        </p>
      )}

      {response.data && <VariantCardStockStatus availability={response.data} />}
    </>
  );
}
