'use client';

import { usePathname } from 'next/navigation';
import { TermFacet } from 'shared/types/result';
import { FNCCategoryFacet } from 'shared/types/result/Facet';
import useMediaQuery from '~/helpers/hooks/useMediaQuery';
import { desktop } from '~/helpers/utils/screensizes';
import FilterTermCheckbox from './filter-term-checkbox';
import Accordion from '../filter-accordion';

interface FilterFacetProp {
  facet: FNCCategoryFacet;
  title: string;
  onClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  disabled: boolean;
}

export interface FilterItem {
  key: string;
  label: string;
  count: number;
  selected: boolean;
  url: string;
}

export default function FilterFacetTerm({ facet, title, onClick, disabled }: FilterFacetProp): JSX.Element {
  const filterItems = (facet as unknown as TermFacet).terms as unknown as FilterItem[];
  const sortedFilterItems = filterItems.sort((a, b) => b.count - a.count);
  const isAccordionOpen = !!filterItems.find((f) => f.selected === true);
  const [isDesktopSize] = useMediaQuery(desktop);
  const pathname = usePathname();

  function generateCheckbox(i: FilterItem) {
    return (
      <FilterTermCheckbox
        filterKey={facet.key}
        label={i.label}
        value={i.key}
        count={i.count}
        selected={i.selected}
        isIndexable={facet.indexable}
        onChange={onClick}
        disabled={disabled}
      />
    );
  }

  function wrappedEl(filterItems: FilterItem[]) {
    const hasSelected = filterItems.find((facet) => facet.selected);

    const el = sortedFilterItems.map((i, k) => {
      const checkbox = generateCheckbox(i);

      if (facet.indexable && !hasSelected) {
        return (
          <a className="my-2 block text-14 text-grey-5" key={k} href={`${pathname}f/${facet.key}/${i.label}`}>
            {checkbox}
          </a>
        );
      } else {
        return (
          <div className="my-2 text-14 text-grey-5" key={k}>
            {checkbox}
          </div>
        );
      }
    });

    return <div>{...el}</div>;
  }

  const filterPanel = wrappedEl(filterItems);

  return (
    <div>
      <Accordion
        contents={[
          {
            headingTag: 'h5',
            headingText: title,
            panel: filterPanel,
            isExpanded: isAccordionOpen ? true : isDesktopSize ? true : false,
          },
        ]}
      />
    </div>
  );
}
