import { useRef, useState } from 'react';
import Link from 'next/link';
import { useParams } from 'next/navigation';
import clsx from 'clsx';
import { FNCProduct, FNCVariant } from 'shared/types/product/Product';
import useColourOptionVariants from '~/helpers/hooks/furniturechoice/use-colour-option-variants';
import useVariantAvailability from '~/helpers/hooks/furniturechoice/use-variant-availability';
import useVariantMainImage from '~/helpers/hooks/furniturechoice/use-variant-main-image';
import ColourOptions from './colour-options';
import StockSummary from './stock-summary';
import ContentfulImage from '../contentful-image';
import DynamicTag from '../dynamic-tag';
import VariantCardPricing from '../variant-card-pricing';
import VariantCardReviews from '../variant-card-reviews';
import VariantSticker from '../variant-sticker';

export type imageSizingType = {
  colSpanPercent?: number;
  colSpanPercentSm?: number;
  colSpanPercentMd?: number;
  colSpanPercentLg?: number;
  colSpanPercentXl?: number;
  colSpanPercent2Xl?: number;
};

interface ProductCardProps {
  headlineAs?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  product: FNCProduct;
  imageSizing: imageSizingType;
}

export default function ProductCard({ headlineAs = 'h2', product, imageSizing }: ProductCardProps) {
  const [activeVariant, setActiveVariant] = useState<FNCVariant>(product.selectedVariant);
  const ref = useRef<HTMLDivElement>(null);
  const { data: mainImage } = useVariantMainImage({ sku: activeVariant.sku, ref });
  const colourOptionVariants = useColourOptionVariants({ sku: product.selectedVariant.sku, ref });
  const availability = useVariantAvailability({ sku: activeVariant.sku, ref });
  const activeUrl = `/p/${activeVariant.slug}`;

  const { locale } = useParams();

  return (
    <div className="flex flex-col gap-2" ref={ref}>
      <Link className="relative" href={activeUrl}>
        <VariantSticker size="small" variant={activeVariant} className="-left-2.5 -top-2.5" />

        <div className={clsx('flex aspect-square overflow-hidden rounded', { 'bg-grey-1': mainImage })}>
          <ContentfulImage
            className="w-full object-contain"
            src={mainImage?.uri}
            alt={mainImage?.alt}
            {...imageSizing}
          />
        </div>
      </Link>

      <ColourOptions
        response={colourOptionVariants}
        activeVariant={activeVariant}
        onColourOptionClick={setActiveVariant}
      />

      <Link className="flex flex-col gap-y-2" href={activeUrl}>
        <div className="flex flex-col gap-y-1">
          <DynamicTag className="font-playfair-display text-20 font-normal leading-5" as={headlineAs}>
            {activeVariant.attributes?.['descriptors_alt-title']}
          </DynamicTag>

          <p className="line-clamp-2 text-12 leading-3.5">{activeVariant.metafields.productName}</p>
        </div>

        <VariantCardReviews variant={activeVariant} />
        <VariantCardPricing className="mt-3" variant={activeVariant} locale={locale} />
        <StockSummary response={availability} />
      </Link>
    </div>
  );
}
